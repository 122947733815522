var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-config-provider", { attrs: { locale: _vm.locale } }, [
    _c(
      "div",
      { attrs: { id: "app" } },
      [
        _c("router-view"),
        _c("loading", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.globalLoading,
              expression: "globalLoading",
            },
          ],
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }