var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("global-footer", {
    staticClass: "footer custom-render",
    scopedSlots: _vm._u([
      {
        key: "links",
        fn: function () {
          return undefined
        },
        proxy: true,
      },
      {
        key: "copyright",
        fn: function () {
          return [
            _c(
              "a",
              {
                attrs: { href: "https://www.czyugang.com/", target: "_blank" },
              },
              [_vm._v("@潮州市宇罡网络科技有限公司")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }