var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loading" }, [
    _c("div", [_c("a-spin", { attrs: { size: "large" } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }